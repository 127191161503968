import { includes, orderBy } from 'lodash'

export class SimilarPostsFactory {
  // (1.) Create by passing in articles, currentSlug
  constructor (posts, currentPostSlug) {
    // (2.) Don't include the current article in articles list
    this.posts = posts.filter(
      (post) => post.slug !== currentPostSlug);

    this.currentArticleSlug = currentPostSlug;
    // (3.) Set default values
    this.maxArticles = 3;
    this.category = null;
    this.tags = []
  }

  // (4.) Builder pattern usage
  setMaxPosts (m) {
    this.maxPosts = m;
    return this;
  }

  setCategory (aCategory) {
    this.category = aCategory;
    return this;
  }

  setTags (tagsArray) {
    this.tags = tagsArray;
    return this;
  }

  getPosts () {
    const { tags, posts, maxPosts } = this;
    let category = this.category;

    // (5.) We use an Identity Map to keep track of score
    const identityMap = {};

    if (!!tags === false || tags.length === 0) {
      console.error('SimilarPostsFactory: Tags not provided, use setTags().')
      return [];
    }

    if (!!category === false) {
      category = 'general';
    }

    function getSlug (article) {
      return article.slug;
    }

    function addToMap (post) {
      const slug = getSlug(post);
      if (!identityMap.hasOwnProperty(slug)) {
        identityMap[slug] = {
          post: post,
          points: 0
        }
      }
    }

    // (7.) Only match tags if the categories are the same
    function addTagsPoints (post, tags) {
        const tagPoint = 1;
        const slug = getSlug(post);
        
        post.tags.forEach((aTag) => {
          if (includes(tags, aTag)) {
            identityMap[slug].points += tagPoint;
          }
        })
    }

    function getIdentityMapAsArray () {
      return Object.keys(identityMap).map((slug) => identityMap[slug]);
    }
    
    // (6.) Map over all articles, add to map and add points
    for (let post of posts) {
      if (post.category === category) {
        addToMap(post);
        addTagsPoints(post, tags)
      }
    }
    
    // (9.) Convert the identity map to an array
    const arrayIdentityMap = getIdentityMapAsArray();

    // (10.) Use a lodash utility function to sort them 
    // by points, from greatest to least
    const similarPosts = orderBy(
      arrayIdentityMap, ['points'], ['desc']
    )

    // Remove any posts that do not have any points
    const reducedSimilarPosts = similarPosts.filter( (post) => {
      return post.points > 0;
    });

    // (11. Take the max number articles requested)
    return reducedSimilarPosts.splice(0, maxPosts);
  }
}