/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from 'styled-components'

import Navbar from './navbar'

import '../assets/css/font-awesome.min.css'
import './layout_basic.css'

//import Header from "./header"
//import '../assets/css/bootstrap.min.css'
//import "./layout.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar />
      {children}
    </React.Fragment>
  )
}

// const Layout = ({ children }) => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => (
//       <>
//         <Header siteTitle={data.site.siteMetadata.title} />
//         <div
//           style={{
//             margin: `0 auto`,
//             maxWidth: 960,
//             padding: `0px 1.0875rem 1.45rem`,
//             paddingTop: 0,
//           }}
//         >
//           <main>{children}</main>
//           <footer>
//             © {new Date().getFullYear()}, Built with
//             {` `}
//             <a href="https://www.gatsbyjs.org">Gatsby</a>
//           </footer>
//         </div>
//       </>
//     )}
//   />
// )

const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,400italic,600italic|Roboto+Slab:400,700");

  * {
      margin: 0;
      padding: 0;
      font: inherit;
      box-sizing: border-box;
  }

  body, input, select, textarea {
      color: #7f888f;
      font-family: "Open Sans", sans-serif;
      font-size: 13pt;
      font-weight: 400;
      line-height: 1.65;
    }

    h1, h2, h3, h4, h5, h6 {
      color: #3d4449;
      font-family: "Roboto Slab", serif;
      font-weight: 700;
      line-height: 1.5;
      margin: 0 0 1em 0;
    }

    h3 {
      font-size: 1.25em;
		}
		
		h1, h2, h3, h4, h5, h6 {
		color: #3d4449;
		font-family: "Roboto Slab", serif;
		font-weight: 700;
		line-height: 1.5;
		margin: 0 0 1em 0;
		}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
			border-bottom: 0;
		}

		h1 {
			font-size: 3em;
			margin: 0 0 0.5em 0;
			line-height: 1.3;
		}

		h2 {
			font-size: 1.75em;
		}

		h3 {
			font-size: 1.25em;
		}

		h4 {
			font-size: 1.1em;
		}

		h5 {
			font-size: 0.9em;
		}

		h6 {
			font-size: 0.7em;
		}

	@media screen and (max-width: 1680px) {

		h1 {
			font-size: 2.5em;
		}

	}

	@media screen and (max-width: 980px) {

		h1 {
			font-size: 2.25em;
		}

	}

	@media screen and (max-width: 736px) {

		h1 {
			font-size: 2em;
			line-height: 1.4;
		}

		h2 {
			font-size: 1.5em;
		}

	}


    blockquote {
		border-left: solid 3px rgba(210, 215, 217, 0.75);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: rgba(230, 235, 237, 0.25);
		border-radius: 0.375em;
		border: solid 1px rgba(210, 215, 217, 0.75);
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

    /* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.3333333333%;
		}

		.row > .off-1 {
			margin-left: 8.3333333333%;
		}

		.row > .col-2 {
			width: 16.6666666667%;
		}

		.row > .off-2 {
			margin-left: 16.6666666667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.3333333333%;
		}

		.row > .off-4 {
			margin-left: 33.3333333333%;
		}

		.row > .col-5 {
			width: 41.6666666667%;
		}

		.row > .off-5 {
			margin-left: 41.6666666667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.3333333333%;
		}

		.row > .off-7 {
			margin-left: 58.3333333333%;
		}

		.row > .col-8 {
			width: 66.6666666667%;
		}

		.row > .off-8 {
			margin-left: 66.6666666667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.3333333333%;
		}

		.row > .off-10 {
			margin-left: 83.3333333333%;
		}

		.row > .col-11 {
			width: 91.6666666667%;
		}

		.row > .off-11 {
			margin-left: 91.6666666667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0;
			margin-left: 0em;
		}

			.row.gtr-0 > * {
				padding: 0 0 0 0em;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0em;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0em;
				}

		.row.gtr-25 {
			margin-top: 0;
			margin-left: -0.375em;
		}

			.row.gtr-25 > * {
				padding: 0 0 0 0.375em;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -0.375em;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 0.375em;
				}

		.row.gtr-50 {
			margin-top: 0;
			margin-left: -0.75em;
		}

			.row.gtr-50 > * {
				padding: 0 0 0 0.75em;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -0.75em;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 0.75em;
				}

		.row {
			margin-top: 0;
			margin-left: -1.5em;
		}

			.row > * {
				padding: 0 0 0 1.5em;
			}

			.row.gtr-uniform {
				margin-top: -1.5em;
			}

				.row.gtr-uniform > * {
					padding-top: 1.5em;
				}

		.row.gtr-150 {
			margin-top: 0;
			margin-left: -2.25em;
		}

			.row.gtr-150 > * {
				padding: 0 0 0 2.25em;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -2.25em;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 2.25em;
				}

		.row.gtr-200 {
			margin-top: 0;
			margin-left: -3em;
		}

			.row.gtr-200 > * {
				padding: 0 0 0 3em;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -3em;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 3em;
				}



		@media screen and (max-width: 1680px) {
			body, input, select, textarea {
				font-size: 11pt;
			}

    }
`

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
