import React from 'react'
import styled from 'styled-components'

import { HeaderArrow } from '.'
import img from '../../assets/images/index.jpg'

function HomeHeader({ img, children }) {
  return ( 
    <IndexHeader img={img}>
       <HeaderArrow />

        {children}
    </IndexHeader>
  )
}

function PageHeader({ img, children }) {
  return ( 
    <DefaultHeader img={img}>
        {children}
    </DefaultHeader>
  )
}

const IndexHeader = styled.header`
    min-height: calc(100vh - 55.78px);
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), 
      url(${props => props.img}) center/cover fixed no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DefaultHeader = styled(IndexHeader)`
    min-height: 60vh;
`

HomeHeader.defaultProps = {
  img: img,
}

DefaultHeader.defaultProps = {
  img: img,
}

export { HomeHeader, PageHeader }