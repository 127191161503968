import React from 'react'
import styled from 'styled-components'

import { styles } from '../utils'
import { BannerArrow } from '../utils'

export default function Banner({title, subtitle, children}) {
  return (
    <BannerWrapper>
      <h1 className="title">{title}</h1>
      <h1 className="subtitle">{subtitle}</h1>
      
      {children}
    </BannerWrapper>
  )
}

Banner.defaultProps = {
  title: 'default title',
  subtitle: 'default subtitle'
}

const BannerWrapper = styled.div`
    margin-bottom: 3rem;
    text-align: center;

    .title {
        color: ${styles.colors.white};
        font-size: 3rem;
        text-transform: uppercase;
        ${styles.letterSpacing({ spacing: '0.75rem'})};
    }

    .subtitle {
        color: ${styles.colors.white};
        font-style: italic;
        ${styles.letterSpacing({ spacing: '0.15rem'})}
        font-size: 1.5 rem;
        text-transform: capitalize;
    }
`
