import styled from 'styled-components'

import { styles } from '../utils'


// const PostButton = styled.button`
// `

const PostButton = styled.button`
/* Button */
	&,
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		background-color: transparent;
		border-radius: 0.375em;
		border: 0;
		box-shadow: inset 0 0 0 2px #f56a6a;
		color: #f56a6a !important;
		cursor: pointer;
		display: inline-block;
		font-family: "Roboto Slab", serif;
		font-size: 0.8em;
		font-weight: 700;
		height: 3.5em;
		letter-spacing: 0.075em;
		line-height: 3.5em;
		padding: 0 2.25em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}
		&:hover,
		.button {
			background-color: rgba(245, 106, 106, 0.05);
		}

		&:active,
		.button {
			background-color: rgba(245, 106, 106, 0.15);
        }
        
		&.icon:before,
		.button {
			margin-right: 0.5em;
		}

		&.fit,
		.button {
			width: 100%;
        }

		&.small,
		.button
		{
			font-size: 0.6em;
		}

		&.large,
		.button {
			font-size: 1em;
			height: 3.65em;
			line-height: 3.65em;
        }
        
		&.primary,
		.button {
			background-color: #f56a6a;
			box-shadow: none;
			color: #ffffff !important;
		}

			&.primary:hover,
		.button {
				background-color: #f67878;
            }
            
			&.primary:active,
		.button {
				background-color: #f45c5c;
            }
            
		&.disabled,
		.button {
			pointer-events: none;
			opacity: 0.25;
		}
`

export { PostButton }
