import React from 'react'
import styled from 'styled-components'

import { styles } from '../utils'

export function HeaderArrow() {
    return (
      <HeaderArrowWrapper>
        <span/>
		<span/>
		<span/>
      </HeaderArrowWrapper>
    )
  }

const HeaderArrowWrapper = styled.div`
	& {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translate(-50%,-50%);
  	}

	& span {
		display: block;
		width: 30px;
		height: 30px;
		border-bottom: 5px solid ${styles.colors.blue};
		border-right: 5px solid ${styles.colors.blue};
		transform: rotate(45deg);
		margin: -10px;
		animation: animate 2s infinite;
	}

	& span:nth-child(2) {
		animation-delay: -0.2s;
	}

	& span:nth-child(3) {
		animation-delay: -0.4s;
	}

	@keyframes animate {
		0% {
			opacity: 0;
			transform: rotate(45deg) translate(-20px, -20px);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			transform: rotate(45deg) translate(20px, 20px);
		}
	}
`