export const fonts = {
    main: '"Open Sans", sans-serif'
}

export const colors = {
    white: `#ffff`,
    black: `#262626`,
    blue: `#11ABC1`,
    red: `#DF3062`,
    lightRed: `#F56A6A`,
    yellow: `#F5B935`,
    green: `#4BAC3F`,
    grey: `#7f888f`,
    background: '#16191f'
}

export const transDefault = `transition: all 0.5s linear`;
export const transition = ({
    property = 'all', 
    time = '0.5s', 
    type = 'ease-in-out'
}) => {
    return `transition: ${property} ${time} ${type}`
}

export const letterSpacing = ({ spacing = '0.1rem' }) => {
    return `letter-spacing: ${spacing}`
};

export const gridColumns = 3;

export const maxWidths = {
    general: '1600px',
    posts: '1600px'
};

export const contentPadding = '1.0875rem';

export const breakPoints = {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px'
}

export const letterSlanted = `${fonts}, cursive;`