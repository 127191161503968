import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { styles } from '../utils'

import logo from '../../assets/images/logo.png'
import { FaAlignRight } from 'react-icons/fa'

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props;

    return (
        <HeaderWrapper>
            <Link to='/'>
                <img src={logo} alt="BlightyInNYC" className="logo" />
            </Link>
            <FaAlignRight className="toggle-icon" onClick={() => {
                handleNavbar()
            }}/>
        </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .toggle-icon {
        font-size: 1.75rem;
        color: ${styles.colors.red};
        cursor: pointer;
    }

    .logo {
        height: 2rem;
    }

    @media (min-width: 768px) {
        .toggle-icon {
            display: none;
        }

        .logo {
            height: 3rem;
        }
    }
`