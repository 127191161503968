import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { FaTwitter, FaInstagram, FaRssSquare } from 'react-icons/fa'

import { styles } from '../utils'

export default class NavbarLinks extends Component {
    state={
        links: [
            {
                id: 0,
                name: 'Home',
                path: '/',
            },
            {
                id: 1,
                name: 'Travel',
                path: '/travel',
            },
            {
                id: 2,
                name: 'About',
                path: '/about',
            },
        ],
        icons: [
            {
              id: 10,
              icon: <FaInstagram className='icon' />,
              url: 'https://www.instagram.com/blighty_in_nyc',
            },
            {
              id: 11,
              icon: <FaTwitter className='icon' />,
              url: 'https://twitter.com/blightyinnyc'
            },
            {
                id: 12,
                icon: <FaRssSquare className='icon' />,
                url: '/rss.xml'
              },
          ]
    }

  render() {
    return (
      <LinkWrapper open={this.props.navbarOpen}>
        {
            this.state.links.map(item => {
                return(
                    <li key={item.id}>
                        <Link to={item.path} className="li-text nav-link">
                            {item.name}
                        </Link>
                    </li>
                )
            })
        }

        {
            <li key='100'>
            {
                this.state.icons.map(icon => {
                    return (
                        <a key={icon.id} href={icon.url} target='_blank' rel='noopener noreferrer'>{icon.icon}</a>
                    )
                })
            }
            </li>
        }
      </LinkWrapper>
    )
  }
}

const LinkWrapper = styled.ul`
    li {
        list-style-type: none;
        text-align: center;
        font-size: 1.3em;
    }

    .nav-link {
        /* display: block; */
        text-decoration: none;
        /* padding: 0.5rem 1rem 0.5rem 1rem; */
        color: ${styles.colors.grey};
        font-weight: 700;
        text-transform: capitalize;
        position: relative;
        text-decoration: none;
        /* ${styles.transDefault}; */
        cursor: pointer;
        /* &:hover {
            background: ${styles.colors.grey};
            color: ${styles.colors.yellow};
            padding: 0.5rem 1rem 0.5rem 1.3rem;
        } */

        &:hover {
            color: ${styles.colors.red};
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: ${styles.colors.grey};
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }

        &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    .icon {
        font-size: 1.7rem;
        color: ${styles.colors.grey};
        ${styles.transition({})};
        width: 2rem;
        justify-content: space-around;
        cursor: pointer;
    }

    .icon:hover {
        color: ${styles.colors.red}
    }

    height: ${props => (props.open? '130px' : '0px')} ;
    overflow: hidden;
    ${styles.transition({})};

    @media (min-width: 768px) {
        height: auto;
        display: flex;
        margin: 0 auto;

        .li-text {
            margin: 0 2rem 0 0;
        }

        .icon {
            width: 3rem;
            transition: none;
            display: none;
        }
    }
`