import React, { Component } from 'react'

import styled from 'styled-components'
import { FaTwitter, FaInstagram, FaRssSquare } from 'react-icons/fa'

import { Link } from 'gatsby'

import { styles } from '../utils'

// TODO: Re-add Instagram once I can create an account

export default class NavbarIcons extends Component {
  state = {
    icons: [
      {
        id: 21,
        icon: <FaInstagram className='icon' />,
        path: 'https://www.instagram.com/blighty_in_nyc'
      },
      {
        id: 22,
        icon: <FaTwitter className='icon' />,
        path: 'https://twitter.com/blightyinnyc'
      },
      {
        id: 23,
        icon: <FaRssSquare className='icon' />,
        path: '/rss.xml'
      },
    ]
  }

  render() {
    return (
      <IconWrapper>
        {
          this.state.icons.map(item => {
            return (
              <a key={item.id} href={item.path} target='_blank' rel='noopener noreferrer'>{item.icon}</a>
            )
          })
        }
      </IconWrapper>
    )
  }
}

const IconWrapper = styled.div`
  .icon {
    font-size: 1.5rem;
    color: ${styles.colors.grey};
    ${styles.transition({})};
    cursor: pointer;
  }

  .icon:hover {
    color: ${styles.colors.red}
  }

  display: none;
  overflow: hidden;
  width: 6rem;
  ${styles.transition({})};
  justify-content: space-around;

  @media(min-width: 768px) {
    display: flex;

    .icon {
      font-size: 1.5rem;
    }
  }
  
/* 
  @media screen and (max-width: 1680px) {
    .icon {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 980px) {
    .icon {
      font-size: 1.5rem;
    }
  } */
`
